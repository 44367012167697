import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);
const commentType = [
  {
    value: 0,
    label: "针对话题的评论",
  },
  {
    value: 1,
    label: "针对评论的回复",
  },
  {
    value: 2,
    label: "针对回复的回复",
  },
];
const { map: commentTypeMap, setOps: commentTypeOps } =
  mapHelper.setMap(commentType);
const topicType = [
  {
    value: 1,
    label: "社区达人风采",
  },
  {
    value: 2,
    label: "超话",
  },
  {
    value: 3,
    label: "活动",
  },
  {
    value: 4,
    label: "广播站",
  },
  {
    value: 5,
    label: "banner广告",
  },
  {
    value: 6,
    label: "创业达人",
  },
];
const { map: topicTypeMap, setOps: topicTypeOps } = mapHelper.setMap(topicType);
export {
  statusMap,
  setStatusOps,
  commentType,
  commentTypeMap,
  topicType,
  topicTypeMap,
};
