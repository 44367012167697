<template>
  <div class="divBox">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isManySelect="true"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="commentType"
          v-model="searchParam.commentType"
          @change="$refs.list.search()"
          label="评论类型"
        />
        <v-select
          clearable
          :options="topicType"
          v-model="searchParam.topicType"
          @change="$refs.list.search()"
          label="评论板块"
        />
        <v-input label="标题" v-model="searchParam.topicTitle" />
        <v-input label="评论内容" v-model="searchParam.commentContent" />
        <v-input label="发布人姓名" v-model="searchParam.userName" />
      </template>
      <template #batchSlot="scope">
        <v-button
          text="批量删除"
          :disabled="scope.selectedData.data.length === 0"
          @click="batchSend(scope.selectedData)"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  getCommentListURL,
  deleteCommentListURL,
  getTopicTypeURL,
} from "./api.js";
import { commentType, commentTypeMap, topicTypeMap } from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      topicType: [],
      commentType,
      searchParam: {
        topicTitle: "",
        commentType: "",
        commentContent: "",
        userName: "",
        topicType: "",
      },

      tableUrl: getCommentListURL,
      headers: [
        {
          prop: "topicTitle",
          label: "标题",
        },
        {
          prop: "commentContent",
          label: "评论内容",
        },
        {
          prop: "topicTypeName",
          label: "所属板块",
        },
        {
          prop: "commentTypeName",
          label: "评论类型",
        },
        {
          prop: "userName",
          label: "评论人",
        },
        {
          prop: "createTime",
          label: "评论时间",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getTopicType();
  },
  methods: {
    batchSend(list) {
      console.log("list--", list);
      const { data, ids } = list;
      var obj = {
        id: ids.join(","),
      };
      this.send(obj);
    },
    send(item) {
      this.toDelete(item);
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteCommentListURL}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },

    getTopicType() {
      let params = {};
      this.topicType = [];
      this.$axios.get(`${getTopicTypeURL}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.text;
            item.value = item.code;
            this.topicType.push(item);
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
